import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react'
import { PlusIcon, MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/20/solid'
import dayjs from 'dayjs'
import _ from 'lodash'
import { CircleDashed } from 'tabler-icons-react'
import { useLocation, useParams } from 'react-router-dom'

// Images
import UsersIcon from '../../assets/images/users.svg'

// Components
import { AddUserModal } from '../../components/AddUserModal'
import { Button } from '../../components/Button'
import { DataTable } from '../../components/DataTable'
import { StateContainer } from '../../components/StateContainer'
import { TextInput } from '../../components/TextInput'
import { Toggle } from '../../components/Toggle'

// Store
import { NavigationStoreContext } from '../../stores/NavigationStore'
import { UserStoreContext } from '../../stores/UserStore'

// Service
import { resendUserInvitation, updateUser } from '../../services/users.service'
import {
  addOrganizationUser,
  getOrganizationUsers,
  updateOrganization,
  updateOrganizationRole,
} from '../../services/organizations.service'

// Utils & Styles
import colors from '../../utils/colors'
import { handlePagination, toast } from '../../utils/helpers'

/**
 *
 * OrganizationUsers
 *
 */
const OrganizationUsers = observer(() => {
  // Context
  const { isOrganizationAdmin, hasOrganizationRole, user } = useContext(UserStoreContext)
  const { organization, setOrganization } = useContext(NavigationStoreContext)
  const location = useLocation()
  const { orgId } = useParams()

  // State
  const [loadingUsers, setLoadingUsers] = useState(true)
  const [users, setUsers] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [filter, setFilter] = useState(null)
  const [showUserModal, setShowUserModal] = useState(false)
  const [loadingResend, setLoadingResend] = useState(false)
  const [loadingUser, setLoadingUser] = useState(false)
  const [editUser, setEditUser] = useState(false)

  const [domainError, setDomainError] = useState(false)
  const [domain, setDomain] = useState(organization.allowedDomain || '')

  // Pagination
  const [currentPage, setCurrentPage] = useState(1)
  const [totalRows, setTotalRows] = useState(0)
  const [perPage, setPerPage] = useState(20)
  const [pages, setPages] = useState(null)

  // Ref
  const searchInputRef = useRef(null)

  const exhibitorView = location.pathname.includes('exhibitor')
  const handleErrors = (m) => toast(m, 'error')
  const handleSuccess = (m) => toast(m, 'success')

  /**
   * Gets the updated list of users; updates pagination.
   * @param {string} url
   * @returns list of results
   */
  const getUpdatedUserList = async (url) => {
    const response = await getOrganizationUsers(url, handleErrors, setLoadingUsers, () => {})

    if (response) {
      setTotalRows(response.count)
      setPages({ next: response.next, previous: response.previous })
      setUsers(response.results)
    }
  }

  /**
   * When the filter or row count changes, get the updated list of users.
   */
  useEffect(() => {
    if (filter) {
      getUpdatedUserList(`/organizations/${orgId}/roles/?expand=user&limit=${perPage}&${filter}`)
    } else {
      getUpdatedUserList(`/organizations/${orgId}/roles/?expand=user&limit=${perPage}`)
    }
  }, [filter, perPage])

  /**
   * Updates the search query based on `search`.
   * @param {string} search
   */
  const updateSearch = (search) => {
    let updatedFilter = ''
    if (search) updatedFilter = `q=${search}`

    setFilter(updatedFilter)
  }

  const filterUsers = useCallback(_.debounce(updateSearch, 500), [])

  const resetForm = () => {
    setEditUser(null)
    setShowUserModal(false)
  }

  /**
   * Handles submitting the add or edit user forms.
   * @param {object} data
   */
  const onSubmit = async (data) => {
    const updatedData = { ...data }
    if (editUser) {
      const updatedUser = { ...updatedData }
      const { roleId: id, role } = updatedUser
      delete updatedUser.phoneNumber
      delete updatedUser.mfaDevice
      delete updatedUser.role

      setLoadingUser(true)
      await updateUser(
        updatedUser,
        handleErrors,
        () => {},
        () => {},
      )

      await updateOrganizationRole(
        { id, organization: orgId, role },
        handleErrors,
        () => {},
        (m) => {
          setLoadingUser(false)
          getUpdatedUserList(`/organizations/${orgId}/roles/?expand=user&limit=${perPage}`)
          handleSuccess(m)
          resetForm()
        },
      )
    } else {
      updatedData.organization = orgId
      updatedData.user = updatedData.email

      delete updatedData.email

      addOrganizationUser(updatedData, handleErrors, setLoadingUser, (m) => {
        getUpdatedUserList(`/organizations/${orgId}/roles/?expand=user&limit=${perPage}`)
        handleSuccess(m)
        resetForm()
      })
    }
  }

  /**
   * Renders the `Status` cell in the DataTable
   * - Displays a pill label based on user `status` or `role`
   * @param {object} row
   */
  const renderStatusCell = (row) => {
    if (row.user.lastLogin === null) {
      return (
        <div className="flex shrink-0 flex-row space-x-6">
          <div className="rounded-full bg-status-blue px-2.5 py-0.5">
            <span className="text-xs font-bold text-status-darkBlue">Invite Pending</span>
          </div>

          {isOrganizationAdmin(orgId) && (
            <button
              disabled={loadingResend && loadingResend === row.id}
              type="button"
              className="px-2 py-0.5 font-bold text-purple hover:rounded-full hover:bg-status-purple disabled:opacity-50"
              onClick={async () =>
                resendUserInvitation(row.user.id, handleErrors, setLoadingResend, (m) => {
                  getUpdatedUserList(`/organizations/${orgId}/roles/?expand=user&limit=${perPage}`)
                  handleSuccess(m)
                })
              }
            >
              <div className="flex flex-row items-center">
                {loadingResend && loadingResend === row.id && (
                  <svg className="mr-1 h-4 w-4 motion-safe:animate-spin-slow" viewBox="0 0 24 24">
                    <CircleDashed size={24} strokeWidth={2} color={colors.purple.DEFAULT} />
                  </svg>
                )}
                Resend
              </div>
            </button>
          )}
        </div>
      )
    }
    return (
      <div className="rounded-full bg-status-green px-2.5 py-0.5">
        <span className="text-xs font-bold text-role-admin">Active</span>
      </div>
    )
  }

  return (
    <>
      <StateContainer>
        <div className="relative flex h-full w-full flex-col space-y-4 overflow-y-auto p-3">
          <div className="flex flex-row items-center justify-between">
            <div className="flex flex-col">
              <div className="flex flex-row items-center space-x-1">
                <img src={UsersIcon} alt="Users" className="h-4" />
                <span className="text-md font-bold">Users</span>
              </div>

              <span className="text-xs">Manage user permissions and add users</span>
            </div>

            {hasOrganizationRole(orgId) && (
              <Button
                background="bg-purple border-purple hover:bg-purple-600"
                className="absolute right-4"
                icon={<PlusIcon className="h-5 sm:h-6" />}
                label="Add User"
                onClick={() => setShowUserModal(true)}
              />
            )}
          </div>

          {exhibitorView && (
            <div className="flex flex-col gap-4">
              <div className="flex flex-col">
                <span className="text-md font-bold">Allowed Domain</span>
                <span className="mb-2 text-xs">
                  Users with email addresses that match the configured domain will be allowed to
                  self-register.
                </span>

                <TextInput
                  className="w-[450px] rounded-2xl border-gray-550 py-2.5 pl-9 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                  icon={<span className="ml-2">@</span>}
                  id="allowedDomain"
                  name="allowedDomain"
                  onChange={(e) => {
                    setDomain(e.target.value)
                    setDomainError(false)
                  }}
                  onBlur={async (e) => {
                    const domainRegex = /[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]{2,4}/

                    // If the domain is not empty, verify it is a valid domain
                    if (e.target.value.length !== 0 && !domainRegex.test(e.target.value)) {
                      setDomainError(true)
                      return
                    }

                    // If the domain is empty, set the allowed domain to null
                    const payload = { id: orgId }
                    if (e.target.value === '') {
                      payload.allowedDomain = null
                      payload.enableRegistrationByDomain = false
                    } else {
                      payload.allowedDomain = e.target.value
                    }

                    const updatedOrganization = await updateOrganization(
                      payload,
                      handleErrors,
                      () => handleSuccess('Allowed domain updated.'),
                    )

                    if (updatedOrganization) setOrganization(updatedOrganization)
                  }}
                  placeholder="domain.com"
                  value={domain}
                />

                {domainError && (
                  <div className="mt-1 w-[450px] bg-error-light px-2 py-1">
                    <p className="text-sm font-medium text-error-dark" id="domain-error">
                      Please enter a valid domain.
                    </p>
                  </div>
                )}
              </div>

              <Toggle
                className="mb-3"
                checked={organization.enableRegistrationByDomain}
                disabled={!organization.allowedDomain || organization.allowedDomain === ''}
                id="enableRegistrationByDomain"
                label="Allow Self-Registration by Domain"
                labelClassName="sm:flex-none"
                onChange={async () => {
                  const updatedOrganization = await updateOrganization(
                    {
                      id: orgId,
                      enableRegistrationByDomain: !organization.enableRegistrationByDomain,
                    },
                    handleErrors,
                    () => handleSuccess('Self-registration updated.'),
                  )

                  if (updatedOrganization) setOrganization(updatedOrganization)
                }}
              />

              <span className="text-md font-bold">Users</span>
            </div>
          )}

          <TextInput
            className="w-full rounded-full py-2.5 pl-10 pr-4  placeholder:font-normal placeholder:text-gray-600 md:w-[450px]"
            icon={<MagnifyingGlassIcon className="ml-2 h-5 text-gray-dark" aria-hidden="true" />}
            id="search"
            endIcon={
              searchTerm ? (
                <button
                  type="button"
                  onClick={() => {
                    filterUsers('')
                    setSearchTerm('')
                    searchInputRef.current.value = ''
                  }}
                >
                  <XMarkIcon className="mr-2 h-5 text-gray-dark" aria-hidden="true" />
                </button>
              ) : null
            }
            name="search"
            onChange={(e) => {
              filterUsers(e.target.value)
              setSearchTerm(e.target.value)
            }}
            placeholder="Looking for something?"
            ref={searchInputRef}
            type="search"
            value={searchTerm}
          />

          <div className="grid w-full">
            <DataTable
              columns={[
                {
                  id: 'name',
                  grow: 1,
                  name: 'Name',
                  selector: (row) => `${row.user.firstName} ${row.user.lastName}`,
                  cell: (row) => (
                    <div className="flex flex-col">
                      <span className="text-sm font-bold text-dark">
                        {row.user.firstName} {row.user.lastName}
                      </span>
                      {row.user.lastLogin && (
                        <span className="text-xs text-gray-600">
                          Last Logged In: {dayjs(row.user.lastLogin).format('M/D/YYYY h:mm A')}
                        </span>
                      )}
                    </div>
                  ),
                  sortable: true,
                  sortBy: 'user__first_name',
                  minWidth: '250px',
                },
                {
                  id: 'email',
                  grow: 1,
                  name: 'Email',
                  selector: (row) => row.user.email,
                  sortable: true,
                  sortBy: 'user__email',
                  minWidth: '220px',
                },
                {
                  id: 'role',
                  grow: 0.25,
                  name: 'Role',
                  selector: (row) => row.role,
                  sortable: true,
                  sortBy: 'role',
                },
                {
                  id: 'status',
                  grow: 0.75,
                  name: 'Status',
                  cell: (row) => renderStatusCell(row),
                },
                {
                  id: 'edit',
                  grow: 0.25,
                  name: '',
                  cell: (row) => (
                    <button
                      className="font-bold text-blue-600 hover:rounded-full hover:bg-status-blue hover:px-2 hover:py-0.5 disabled:cursor-not-allowed disabled:opacity-50"
                      disabled={row.user.id === user.id}
                      type="button"
                      onClick={() => {
                        const userToEdit = {
                          // User information
                          id: row.user.id,
                          firstName: row.user.firstName,
                          lastName: row.user.lastName,
                          email: row.user.email,
                          phoneNumber: row.user.phoneNumber,
                          mfaDevice: row.user.mfaDevice,
                          // Organization role information
                          role: row.role,
                          roleId: row.id,
                        }

                        setEditUser(userToEdit)
                        setShowUserModal(true)
                      }}
                    >
                      Edit
                    </button>
                  ),
                  center: true,
                },
              ]}
              data={users}
              defaultSortFieldId="name"
              defaultSortAsc
              onChangePage={(page) =>
                handlePagination(
                  page,
                  currentPage,
                  perPage,
                  totalRows,
                  pages,
                  setCurrentPage,
                  getUpdatedUserList,
                  `/organizations/${orgId}/roles/?expand=user&limit=`,
                  filter,
                )
              }
              onChangeRowsPerPage={async (currentRowsPerPage) => setPerPage(currentRowsPerPage)}
              onSort={(column, direction) => {
                const d = direction === 'asc' ? '' : '-'
                const url = `/organizations/${orgId}/roles/?expand=user&order_by=${d}${column.sortBy}&limit=${perPage}`
                getUpdatedUserList(url)
              }}
              pagination
              paginationPerPage={perPage}
              paginationRowsPerPageOptions={[10, 15, 20, 30, 50]}
              paginationTotalRows={totalRows}
              paginationServer
              progressPending={loadingUsers}
              sortServer
            />
          </div>
        </div>
      </StateContainer>

      {showUserModal && (
        <AddUserModal
          loadingUser={loadingUser}
          onSubmit={onSubmit}
          editUser={editUser}
          roles={{
            allowRoleChange: exhibitorView ? !editUser : true,
            key: 'role',
            options: [
              {
                label: 'Admin',
                description:
                  'Admins can access all Events and manage Qualifiers and Materials. Admins can also add, edit, and delete other Users.',
              },
              {
                label: 'Booth User',
                description:
                  'Booth Staff will only have access to Events they are assigned Licenses for Booth Staff can be managed from the Event License page.',
                disabled: true,
              },
            ],
            showRoleDescriptions: !!exhibitorView,
          }}
          setEditUser={setEditUser}
          showRoleDescriptions
          showUserModal={showUserModal}
          setShowUserModal={setShowUserModal}
        />
      )}
    </>
  )
})

export default OrganizationUsers
