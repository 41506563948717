/* eslint-disable jsx-a11y/media-has-caption */
import React, { useContext, useEffect, useState } from 'react'
import { twMerge as mergeClassNames } from 'tailwind-merge'
import dayjs from 'dayjs'
import { observer } from 'mobx-react'

// Components
import { EventHeader } from '../../components/EventHeader'
import { EventKPITile } from '../../components/EventKPITile'
import { Modal } from '../../components/Modal'
import { StateContainer } from '../../components/StateContainer'
import { TasklistTile } from '../../components/TasklistTile'
import { FilterIcon } from '../../components/FilterIcon'

// Images
import Check from '../../assets/images/check.svg'

// Stores
import { NavigationStoreContext } from '../../stores/NavigationStore'

// Service
import { getEvent } from '../../services/events.service'
import {
  completeExhibitorWatchVideoTask,
  getExhibitor,
  getExhibitorKPI,
} from '../../services/exhibitors.service'

// Style & Utils
import { configureActiveBackgroundStyles, configureActiveTextStyle } from '../../utils/colors'
import { toast } from '../../utils/helpers'

// Video
import videoUrl from '../../assets/video/welcome-video-exhibitor-task.mp4'

/**
 *
 * ExhibitorEventHome
 *
 */
const ExhibitorEventHome = observer(() => {
  // Context
  const { event, eventId, organizationId, setEvent } = useContext(NavigationStoreContext)

  // State
  const [KPI, setKPI] = useState(null)
  const [loading, setLoading] = useState(false)
  const [tasks, setTasks] = useState(null)
  const [showVideoModal, setShowVideoModal] = useState(false)
  const [videoEnded, setVideoEnded] = useState(false)

  const handleErrors = (m) => toast(m, 'error')

  // Load initial data
  useEffect(() => {
    const loadData = async () => {
      setLoading(true)

      const updatedEvent = await getEvent(
        organizationId,
        eventId,
        handleErrors,
        () => {},
        () => {},
      )

      if (updatedEvent && updatedEvent.eventExhibitor) {
        setEvent(updatedEvent)

        const exhibitor = await getExhibitor(
          eventId,
          updatedEvent.eventExhibitor.id,
          handleErrors,
          () => {},
          () => {},
        )

        if (exhibitor) {
          setTasks(exhibitor.tasklistItems)
        }

        const exhibitorKPI = await getExhibitorKPI(
          eventId,
          updatedEvent.eventExhibitor.id,
          handleErrors,
          () => {},
        )

        if (exhibitorKPI) {
          // convert leadsPerDay object into an array
          // so that it can be plotted in a nivo bar chart
          const leadsPerDayArray = Object.entries(exhibitorKPI.leadsPerDay).map((entry) => {
            const key = entry[0]
            const value = entry[1]
            return {
              date: key,
              leads: value,
            }
          })
          const leadsDesc = (a, b) => b.totalLeadCount - a.totalLeadCount
          exhibitorKPI.leadsByUser.sort(leadsDesc)

          setKPI({
            ...exhibitorKPI,
            leadsPerDay: leadsPerDayArray,
          })
        }
      }

      setLoading(false)
    }

    if (eventId) {
      loadData()
    }
  }, [eventId])

  return (
    <div className="h-full w-full">
      <StateContainer loading={loading}>
        <div className="relative flex h-full w-full flex-col space-y-3 overflow-y-auto p-3">
          <div className="flex w-full flex-col items-start justify-between sm:flex-row sm:items-center">
            <div
              className={mergeClassNames(
                'flex h-6 flex-row place-items-center space-x-1.5 rounded-full bg-status-green pl-3 pr-4 shadow-sm sm:hidden',
                configureActiveBackgroundStyles(event),
                event?.status === 'Active' && 'pr-4',
              )}
            >
              {event?.status === 'Active' && <img alt="Check" className="h-5" src={Check} />}
              <span
                className={mergeClassNames('text-xs font-medium', configureActiveTextStyle(event))}
              >
                {event?.status}
              </span>
            </div>

            <EventHeader event={event} />

            <div
              className={mergeClassNames(
                'hidden h-8 flex-row place-items-center space-x-1.5 rounded-full pl-3 pr-3 shadow-sm sm:flex',
                configureActiveBackgroundStyles(event),
                event?.status === 'Active' && 'pr-4',
              )}
            >
              {event?.status === 'Active' && <img alt="Check" className="h-5" src={Check} />}
              <span
                className={mergeClassNames(
                  'text-xs font-medium lg:text-sm',
                  configureActiveTextStyle(event),
                )}
              >
                {event?.status}
              </span>
            </div>
          </div>

          <div className="flex h-auto w-full flex-col space-y-8 md:grid md:grid-cols-2 md:gap-8 md:space-y-0 lg:grid-cols-3">
            <div className="h-max w-full shrink-0 md:col-span-2 md:row-span-3 md:h-full lg:col-span-1 lg:row-span-3">
              <TasklistTile
                eventUrlPrefix={`/exhibitor/${organizationId}/event/${eventId}`}
                tasklist={tasks}
                setShowVideoModal={setShowVideoModal}
              />
            </div>
            {KPI && (
              <>
                <div className="h-42 w-full md:col-span-1 md:row-span-1">
                  <EventKPITile
                    className="h-full"
                    data={{ main: KPI?.totalLeadCount || 0 }}
                    label="Total Leads"
                    labelIcon={<FilterIcon className="h-4 stroke-black" />}
                    type="event-leads"
                  />
                </div>
                <div className="h-42 w-full md:col-span-1 md:row-span-1">
                  <EventKPITile
                    backgroundColor="bg-teal"
                    className="h-full"
                    data={{ main: KPI?.totalGreatLeadCount || 0 }}
                    label="Great Leads"
                    labelIcon={<div className="text-xl">🥳</div>}
                    type="event-leads"
                  />
                </div>
              </>
            )}

            <div className="h-full w-full md:col-span-2 md:row-span-1">
              {KPI && (
                <EventKPITile data={KPI.leadsByUser} label="Leads Per Device" type="per-device" />
              )}
            </div>
            <div className="h-48 w-full md:col-span-2 md:row-span-1">
              {KPI && event && (
                <EventKPITile
                  data={KPI.leadsPerDay}
                  label="Leads Per Day"
                  subLabel={
                    dayjs(event.startsAt).isAfter(dayjs())
                      ? 'Come back when the event starts to see number of leads per day.'
                      : 'Hover over the bar graph to see the leads for that day.'
                  }
                  type="bar"
                />
              )}
            </div>
          </div>
        </div>

        <Modal
          actions={[
            {
              type: 'cancel',
              label: 'Cancel',
              onClick: () => setShowVideoModal(false),
            },
            {
              type: 'submit',
              label: 'Done',
              onClick: async () => {
                // mark task as complete
                await completeExhibitorWatchVideoTask(eventId, event.eventExhibitor.id)
                setShowVideoModal(false)

                // get updated tasklist
                const exhibitor = await getExhibitor(
                  eventId,
                  event.eventExhibitor.id,
                  handleErrors,
                  () => {},
                  () => {},
                )

                if (exhibitor) {
                  setTasks(exhibitor.tasklistItems)
                }
              },
              disabled: !videoEnded,
            },
          ]}
          content={
            <div className="flex flex-row justify-center py-3 sm:py-5">
              <video controls onEnded={() => setVideoEnded(true)}>
                <source src={videoUrl} type="video/mp4" />
              </video>
            </div>
          }
          open={showVideoModal}
          title="Watch Eventstack Video"
        />
      </StateContainer>
    </div>
  )
})

export default ExhibitorEventHome
