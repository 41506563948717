import React from 'react'
import { isPlainObject } from 'lodash'
import { useImmer } from 'use-immer'
import { toast as displayToast } from 'react-hot-toast'
import Fernet from 'fernet-web'

// Component
import { Alert } from '../components/Alert'
import {
  oneUppercaseLetterRegEx,
  oneLowercaseLetterRegEx,
  oneNumberRegEx,
  oneSymbolRegEx,
} from '../components/PasswordVerifier'

const ERROR_DEFAULT = 'An error occurred. Please try again.'
const ERROR_NO_INTERNET = 'Request could not be made. Please check your internet connection.'
const ERROR_TIMEOUT = 'Request timed out. Please try again.'

export const createWithDoc = ({ envName = '', docFunction = () => {}, component = '' }) => {
  let createComponentWithDoc
  if (envName !== 'production') {
    createComponentWithDoc = docFunction(component) // eslint-disable-line global-require
  }
  return createComponentWithDoc || component
}

export const getErrorMessage = (err) => {
  // - Axios does not return a response object if the service cannot be reached
  if (err.code === 'ERR_NETWORK') {
    return ERROR_NO_INTERNET
  }

  if (err.code === 'ECONNABORTED' || err.code === 'ETIMEDOUT') {
    return ERROR_TIMEOUT
  }

  // - If we do have a response and data object, use the full error object
  if (err.response && err.response.data) {
    try {
      const errorStringOrObject = Object.values(err.response.data)[0]
      if (isPlainObject(errorStringOrObject)) return errorStringOrObject[0]
      return errorStringOrObject
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn('Could not infer error message from error response object')
    }
  }

  // If a message has not been set, fallback to our default message
  return ERROR_DEFAULT
}

/**
 * Updates the state object with the updated field.
 * @param {object} state
 * @param {object} updatedField
 */
export const updateState = (state, updatedField) => ({
  ...state,
  ...updatedField,
})

/**
 * Updates an array stored in state.
 * - `add`: adds value to the array
 * - `remote`: removes the value at the index from the array
 * - `update`: updates the value at the index in the array
 * - `reset` and `filter`: returns the supplied value
 * - `clear`: removes all values from the array
 * @param {object} state
 * @param {object} updatedState
 */
export const updateArrayState = (state, { type, value, index }) => {
  switch (type) {
    case 'add':
      return [...state, value]
    case 'remove': {
      const updatedState = [...state]
      updatedState.splice(index, 1)
      return updatedState
    }
    case 'update': {
      const updatedState = [...state]
      updatedState[index] = value
      return updatedState
    }
    case 'reset':
    case 'filter':
      return value
    case 'clear':
      return []
    default:
      return state
  }
}

export const joinClassNames = (...classes) => classes.filter(Boolean).join(' ')

export const useImmerState = useImmer

export const toast = (message, type) =>
  displayToast.custom(
    (t) =>
      t.visible && (
        <Alert message={message} type={type} onClose={() => displayToast.dismiss(t.id)} />
      ),
    { id: message },
  )

/**
 * Handles updating pagination based on the supplied parameters and functions.
 * @param {number} page
 * @param {number} currentPage
 * @param {number} perPage
 * @param {number} totalRows
 * @param {object} pages
 * @param {func} setCurrentPage
 * @param {func} request
 * @param {string} baseUrl
 * @param {string} filter
 */
export const handlePagination = async (
  page,
  currentPage,
  perPage,
  totalRows,
  pages,
  setCurrentPage,
  request,
  baseUrl,
  filter = null,
) => {
  // If the user is requesting the first page and we are not on the next page,
  // we need to get the very first page and not utilize `previous`.
  if (page === 1 && currentPage > 1) {
    await request(`${baseUrl}${perPage}&${filter ? `${filter}&` : ''}page=1`)
  }
  // If the user is requesting the last page and we are not on the previous page,
  // we need to get the very last page and not utilize `next`.
  else if (page > currentPage && page - currentPage > 1) {
    await request(
      `${baseUrl}${perPage}&${filter ? `${filter}&` : ''}page=${Math.ceil(totalRows / perPage)}`,
    )
  }
  // If the user is requesting the next page.
  else if (page > currentPage) {
    await request(pages.next)
  }
  // Otherwise, the user is requesting the previous page.
  else {
    await request(pages.previous)
  }

  setCurrentPage(page)
}

/**
 * Handles verifying the password against requirements.
 * @param {string} value
 * @returns {string} error if there is one to display
 */
export const verifyPassword = (value) => {
  if (!value || value.length < 8) return 'Please enter more than 8 characters'
  if (!oneUppercaseLetterRegEx.test(value)) return 'Please enter at least 1 uppercase letter'
  if (!oneLowercaseLetterRegEx.test(value)) return 'Please enter at least 1 lowercase letter'
  if (!oneNumberRegEx.test(value)) return 'Please enter at least 1 number'
  if (!oneSymbolRegEx.test(value)) return 'Please enter at least 1 symbol'
  return undefined
}

/**
 * Sourced from: https://github.com/granteagon/move
 *
 * Moves an item in an array at the specified index to another position in the array.
 * @param {array} array
 * @param {number} moveIndex
 * @param {number} toIndex
 * @returns updated array
 */
export const move = (array, moveIndex, toIndex) => {
  /* #move - Moves an array item from one position in an array to another.

     Note: This is a pure function so a new array will be returned, instead
     of altering the array argument.

    Arguments:
    1. array     (String) : Array in which to move an item.         (required)
    2. moveIndex (Object) : The index of the item to move.          (required)
    3. toIndex   (Object) : The index to move item at moveIndex to. (required)
  */
  const item = array[moveIndex]
  const { length } = array
  const diff = moveIndex - toIndex

  if (diff > 0) {
    // move left
    return [
      ...array.slice(0, toIndex),
      item,
      ...array.slice(toIndex, moveIndex),
      ...array.slice(moveIndex + 1, length),
    ]
  }

  if (diff < 0) {
    // move right
    const targetIndex = toIndex + 1
    return [
      ...array.slice(0, moveIndex),
      ...array.slice(moveIndex + 1, targetIndex),
      item,
      ...array.slice(targetIndex, length),
    ]
  }
  return array
}

export const decrypt = async (data) => {
  const fernet = await Fernet.create(import.meta.env.VITE_FERNET_SECRET)
  const decrypted = await fernet.decrypt(data)
  return decrypted.toString()
}
