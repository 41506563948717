import React, { useEffect, useMemo, useRef, useState } from 'react'
import { twMerge as mergeClassNames } from 'tailwind-merge'
import PropTypes from 'prop-types'
import _ from 'lodash'
import {
  ClipboardDocumentListIcon,
  PencilIcon,
  PlusIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline'
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/20/solid'
import { Controller, useFieldArray, useForm } from 'react-hook-form'

// Components
import { Button } from '../../components/Button'
import { Modal } from '../../components/Modal'
import { TextInput } from '../../components/TextInput'
import { Select } from '../../components/Select'

// Utils & Service
import { toast } from '../../utils/helpers'
import {
  createCustomPopupMessage,
  deleteCustomPopupMessage,
  updateCustomPopupMessage,
} from '../../services/kiosks.service'

const POPUP_IDENTIFIERS = [
  { label: 'Attendee', id: 'attendee-welcome' },
  { label: 'Staff', id: 'staff-welcome' },
]

const OPERATOR_OPTIONS = [
  { label: 'Is', id: 'is' },
  { label: 'Contains', id: 'contains' },
  { label: 'Populated', id: 'populated' },
]

const INCLUSION_OPTIONS = [
  { label: 'AND', id: 'AND' },
  { label: 'OR', id: 'OR' },
]

const DEFAULT_LOGIC = {
  inclusionLogic: INCLUSION_OPTIONS[0],
  mergeField: null,
  operator: null,
  value: null,
}

const CustomPopupModal = ({
  customPopup = null,
  eventId,
  kioskConfigurationId,
  mergeFields,
  onClose,
}) => {
  // State
  const [loadingCustomMessage, setLoadingCustomMessage] = useState(false)
  const [filteredMergeFields, setFilteredMergeFields] = useState(mergeFields)
  const [searchTerm, setSearchTerm] = useState('')

  // Ref
  const searchInputRef = useRef()

  const handleError = (message) => toast(message, 'error')
  const handleSuccess = (message) => toast(message, 'success')

  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
    getValues,
    reset,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      message: '',
      popupIdentifier: POPUP_IDENTIFIERS[0],
      popupMessageLogic: [DEFAULT_LOGIC],
    },
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'popupMessageLogic',
    rules: { required: true },
  })

  useEffect(() => {
    if (customPopup) {
      reset({
        message: customPopup.message,
        inclusionLogic: INCLUSION_OPTIONS.find(
          (o) => o.id === customPopup.popupMessageLogic[0].inclusionLogic,
        ),
        popupIdentifier: POPUP_IDENTIFIERS.find((o) => o.id === customPopup.popupIdentifier),
        popupMessageLogic: _.map(customPopup.popupMessageLogic, (l) => ({
          ...l,
          inclusionLogic: INCLUSION_OPTIONS.find((o) => o.id === l.inclusionLogic),
          mergeField: MERGE_FIELD_OPTIONS.find((o) => o.id === l.mergeField),
          operator: _.find(OPERATOR_OPTIONS, (o) => o.id === l.operator),
        })),
      })
    }
  }, [customPopup])

  const MERGE_FIELD_OPTIONS = _.map(mergeFields, (f) => ({ label: f, id: f }))

  const inclusionLogicButtons = useMemo(() => {
    if (fields.length === 5) return null

    if (fields.length === 1) {
      return (
        <div className="flex flex-row gap-4">
          <Button
            className="border-gray-200 bg-gray-200 text-gray-700 hover:bg-gray-300"
            icon={<PlusIcon className="h-5 stroke-gray stroke-2" />}
            label="AND"
            onClick={() => {
              append(DEFAULT_LOGIC)
              setValue('inclusionLogic', INCLUSION_OPTIONS[0])
            }}
          />

          <Button
            className="border-gray-200 bg-gray-200 text-gray-700 hover:bg-gray-300"
            icon={<PlusIcon className="h-5 stroke-gray stroke-2" />}
            label="OR"
            onClick={() => {
              append({ ...DEFAULT_LOGIC, inclusionLogic: INCLUSION_OPTIONS[1] })
              setValue('inclusionLogic', INCLUSION_OPTIONS[1])
            }}
          />
        </div>
      )
    }

    if (getValues('popupMessageLogic.0.inclusionLogic')?.id === 'AND') {
      return (
        <Button
          className="border-gray-200 bg-gray-200 text-gray-700 hover:bg-gray-300"
          icon={<PlusIcon className="h-5 stroke-gray stroke-2" />}
          label="AND"
          onClick={() => append(DEFAULT_LOGIC)}
        />
      )
    }

    return (
      <Button
        className="border-gray-200 bg-gray-200 text-gray-700 hover:bg-gray-300"
        icon={<PlusIcon className="h-5 stroke-gray stroke-2" />}
        label="OR"
        onClick={() => append({ ...DEFAULT_LOGIC, inclusionLogic: INCLUSION_OPTIONS[1] })}
      />
    )
  }, [fields, watch('popupMessageLogic.0.inclusionLogic')])

  const actions = [
    {
      type: 'cancel',
      label: 'Cancel',
      onClick: () => {
        setFilteredMergeFields(mergeFields)
        onClose()
      },
    },
    {
      type: 'submit',
      label: customPopup ? 'Update' : 'Submit',
      onClick: handleSubmit(async (data) => {
        const updatedData = { ...data, popupIdentifier: data.popupIdentifier.id }

        if (updatedData.popupIdentifier === 'attendee-welcome') {
          updatedData.audience = 'Attendee'
        } else {
          updatedData.audience = 'Staff'
        }

        const logic = _.map(updatedData.popupMessageLogic, (l) => ({
          ...l,
          mergeField: l.mergeField.id,
          operator: l.operator.id,
          inclusionLogic: l.inclusionLogic.id,
        }))
        updatedData.popupMessageLogic = logic

        if (customPopup) {
          await updateCustomPopupMessage(
            eventId,
            kioskConfigurationId,
            { id: customPopup.id, ...updatedData },
            handleError,
            setLoadingCustomMessage,
            (m) => {
              handleSuccess(m)
              onClose()
            },
          )
        } else {
          await createCustomPopupMessage(
            eventId,
            kioskConfigurationId,
            updatedData,
            handleError,
            setLoadingCustomMessage,
            (m) => {
              handleSuccess(m)
              onClose()
            },
          )
        }
      }),
    },
  ]

  if (customPopup) {
    actions.push({
      type: 'delete',
      label: 'Delete',
      onClick: () =>
        deleteCustomPopupMessage(
          eventId,
          kioskConfigurationId,
          customPopup.id,
          handleError,
          setLoadingCustomMessage,
          (m) => {
            handleSuccess(m)
            onClose()
          },
        ),
    })
  }

  return (
    <Modal
      actions={actions}
      icon={
        customPopup ? (
          <PencilIcon className="h-5 stroke-white sm:h-6" />
        ) : (
          <PlusIcon className="h-5 stroke-white sm:h-6" />
        )
      }
      containerClassName="h-[90%]"
      content={
        <div className="flex h-full w-full flex-col gap-4 py-3 text-center sm:py-5">
          <span className="text-center font-nunito italic text-gray-550">
            Set up a custom message to display on either the Attendee or Staff Welcome popup.
          </span>

          <div className="flex h-full w-full flex-row space-x-4 overflow-y-hidden">
            <div className="flex h-full w-3/4 flex-col gap-4 overflow-y-auto">
              <TextInput
                className="rounded-2xl border-gray-550 py-2.5 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                data-testid="message"
                disabled={loadingCustomMessage}
                error={errors.message && 'This field is required'}
                fullWidth
                id="message"
                inputStyles="rounded-none"
                name="message"
                nunito
                label="Custom Message"
                placeholder="Custom Message"
                {...register('message', { required: true })}
              />

              <Controller
                name="popupIdentifier"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    className="rounded-2xl border-gray-550 py-2.5 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                    data-testid="popupIdentifier"
                    disabled={loadingCustomMessage}
                    error={errors.popupIdentifier && 'This field is required'}
                    fullWidth
                    id="popupIdentifier"
                    name="popupIdentifier"
                    nunito
                    onChange={onChange}
                    options={POPUP_IDENTIFIERS}
                    label="Audience"
                    placeholder="Select an Audience"
                    style={{ flex: true, width: '100%' }}
                    value={value}
                  />
                )}
                rules={{ required: true }}
              />

              {fields.length > 1 && (
                <Controller
                  name="inclusionLogic"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      className="rounded-2xl border-gray-550 py-2.5 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                      data-testid="inclusionLogic"
                      disabled={loadingCustomMessage}
                      error={errors.inclusionLogic && 'This field is required'}
                      fullWidth
                      id="inclusionLogic"
                      name="inclusionLogic"
                      nunito
                      onChange={(d) => {
                        onChange(d)

                        // Update the inclusion logic for each logic field
                        const logic = INCLUSION_OPTIONS.find((o) => o.id === d.id)
                        _.forEach(fields, (l, i) => {
                          setValue(`popupMessageLogic.${i}.inclusionLogic`, logic)
                        })
                      }}
                      options={INCLUSION_OPTIONS}
                      label="Inclusion Logic"
                      placeholder="Select a Inclusion Logic"
                      style={{ flex: true, width: '100%' }}
                      value={value}
                    />
                  )}
                  rules={{ required: true }}
                />
              )}

              <div className="mb-10 flex h-full w-full flex-col gap-4">
                <span className="text-left font-nunito font-semibold">Logic Fields</span>

                <div className="flex w-full flex-col gap-4">
                  {_.map(fields, (field, i) => {
                    const key = `popupMessageLogic.${i}`
                    return (
                      <div className="flex w-full flex-row" key={field.id}>
                        <div
                          className={mergeClassNames(
                            'flex h-full w-9 shrink-0 flex-col items-center justify-center gap-1',
                            i === 0 && 'w-0',
                            i !== 0 && 'mr-4',
                          )}
                        >
                          {i > 0 && (
                            <span className="rounded-lg bg-gray-200 p-1 text-xs font-semibold text-gray-700">
                              {getValues('inclusionLogic')?.label}
                            </span>
                          )}

                          {i !== 0 && (
                            <Button
                              icon={<XCircleIcon className="h-6 stroke-inherit" />}
                              className="rounded-full stroke-red p-0 hover:stroke-red-600"
                              iconOnly
                              onClick={() => remove(i)}
                            />
                          )}
                        </div>

                        <div className="flex w-full flex-row gap-4">
                          <Controller
                            name={`${key}.mergeField`}
                            control={control}
                            render={({ field: { onChange, value } }) => (
                              <Select
                                className="rounded-2xl border-gray-550 py-2.5 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                                data-testid={`${key}.mergeField`}
                                disabled={loadingCustomMessage}
                                error={errors[field] && 'This field is required'}
                                fullWidth
                                id={`${key}.mergeField`}
                                label="Merge Field"
                                name={`${key}.mergeField`}
                                nunito
                                onChange={onChange}
                                options={MERGE_FIELD_OPTIONS}
                                placeholder="Select a Merge Field"
                                search
                                style={{ flex: true, width: '100%' }}
                                value={value}
                              />
                            )}
                            rules={{ required: true }}
                          />

                          <Controller
                            name={`${key}.operator`}
                            control={control}
                            render={({ field: { onChange, value } }) => (
                              <Select
                                className="rounded-2xl border-gray-550 py-2.5 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                                data-testid={`${key}.operator`}
                                disabled={loadingCustomMessage}
                                error={errors[field] && 'This field is required'}
                                fullWidth
                                id={`${key}.operator`}
                                label="Operator"
                                name={`${key}.operator`}
                                nunito
                                onChange={onChange}
                                options={OPERATOR_OPTIONS}
                                placeholder="Select an Operator"
                                style={{ flex: true, width: '100%' }}
                                value={value}
                              />
                            )}
                            rules={{ required: true }}
                          />

                          <TextInput
                            className="rounded-2xl border-gray-550 py-2.5 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                            data-testid={`${key}.value`}
                            disabled={loadingCustomMessage}
                            error={errors.message && 'This field is required'}
                            fullWidth
                            id={`${key}.value`}
                            inputStyles="rounded-none"
                            name={`${key}.value`}
                            nunito
                            label="Value"
                            placeholder="Value"
                            {...register(`${key}.value`, { required: true })}
                          />
                        </div>
                      </div>
                    )
                  })}
                </div>

                <div className="flex w-full flex-row justify-center">{inclusionLogicButtons}</div>
              </div>
            </div>

            <div className="h-[95%] w-[1px] bg-black" />

            <div className="flex w-1/4 flex-col">
              <span className="w-fit pb-1 font-nunito text-sm font-medium text-gray-700">
                Merge Fields
              </span>

              <TextInput
                autoComplete="off"
                className="w-full self-center rounded-lg py-2.5 pl-10 pr-4 placeholder:font-normal placeholder:text-gray-600"
                icon={<MagnifyingGlassIcon className="ml-2 h-5 text-purple" aria-hidden="true" />}
                id="search"
                endIcon={
                  searchTerm ? (
                    <button
                      type="button"
                      onClick={() => {
                        setFilteredMergeFields(mergeFields)
                        setSearchTerm('')
                        searchInputRef.current.value = ''
                      }}
                    >
                      <XMarkIcon className="mr-2 h-5 text-gray-dark" aria-hidden="true" />
                    </button>
                  ) : null
                }
                name="search"
                onChange={(e) => {
                  setFilteredMergeFields(
                    _.filter(mergeFields, (o) => o.toLowerCase().includes(e.target.value)),
                  )

                  setSearchTerm(e.target.value)
                }}
                placeholder="Search"
                ref={searchInputRef}
                type="text"
                value={searchTerm}
              />

              <div className="mb-5 mt-2 overflow-y-scroll">
                {filteredMergeFields.length > 0 ? (
                  _.map(filteredMergeFields, (f, i) => (
                    <button
                      className="group flex cursor-pointer flex-row"
                      key={`${f}:${i}`}
                      onClick={() => {
                        navigator.clipboard.writeText(`{{ ${f} }}`)
                        handleSuccess('Merge field copied to clipboard!')
                      }}
                      type="button"
                    >
                      <span className="group-hover:text-purple">{f}</span>

                      <ClipboardDocumentListIcon className="hidden h-6 group-hover:flex group-hover:stroke-purple" />
                    </button>
                  ))
                ) : (
                  <span className="self-start text-sm text-gray-800">
                    No Merge Fields Available.
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      }
      className="sm:h-[90vh] sm:max-h-[1200px] sm:w-[90vw] sm:max-w-screen-xl"
      open
      title={`${customPopup ? 'Edit' : 'Add'} Custom Message`}
    />
  )
}

CustomPopupModal.propTypes = {
  customPopup: PropTypes.object,
  eventId: PropTypes.string.isRequired,
  kioskConfigurationId: PropTypes.string.isRequired,
  mergeFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClose: PropTypes.func.isRequired,
}

export default CustomPopupModal
